.accordion__title {
  font-family: '"Segoe UI", "Nunito Sans", "Roboto", "Helvetica", "Arial", "sans-serif"';
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.accordion__section {
  width: 100%;
}

.accordion__content {
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.accordion__text {
  cursor: pointer;
}

.highlighted__element {
  background-color: rgb(211, 211, 211, 0.5);
  color: #007bff !important;
}
